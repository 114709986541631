const development = {
	url: `${process.env.REACT_APP_API_URL}`,
};

const production = {
	url: `${process.env.REACT_APP_API_URL}`,
};

const config = {
	development,
	production,
};

const env = process.env.NODE_ENV || 'production';

module.exports = config[env];
