import React, { useEffect, useState } from 'react';
import axios from 'axios';

function EmpresaCertificado() {
  const [page, setPage] = useState([]);
  const [page2, setPage2] = useState([]);

  useEffect(() => {
    axios.get('https://sheets.googleapis.com/v4/spreadsheets/1pcM8B5DbB5H8q1J_wH_jkh2TCH7Ui9RjQ3ZhUFOL4p0/values/selo_etica', {
      params: {
        key: 'AIzaSyAu5sW7jP1joq0sKQ362a9VLuVmWhAD0tM'
      }
    })
      .then(response => {
        setPage(response.data.values);
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('https://sheets.googleapis.com/v4/spreadsheets/1pcM8B5DbB5H8q1J_wH_jkh2TCH7Ui9RjQ3ZhUFOL4p0/values/perfil_maturidade', {
      params: {
        key: 'AIzaSyAu5sW7jP1joq0sKQ362a9VLuVmWhAD0tM'
      }
    })
      .then(response => {
        setPage2(response.data.values);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const renderRows = (rows) => {
    return rows.slice(1).map((row, index) => (
      <tr key={index}>
        <td>{row[0]}</td>
        <td className="table-clar">{row[1]}</td>
        <td>{row[2]}</td>
        <td className="table-clar">{row[3]}</td>
      </tr>
    ));
  };
  const totalRows = page.length + page2.length - 2;

  return (
    <div className="font-mobile">
      <p className="p-title-info"><b>Selo de Ética</b></p>
      <table className="w3-table-all" style={{ marginTop: 30 }}>
        <thead>
          <tr>
            <th className="table-cor-clara">Empresa</th>
            <th className="table-cor-escura">Sites Auditados</th>
            <th className="table-cor-clara">Validade</th>
            <th className="table-cor-escura">Órgão Certificador</th>
          </tr>
        </thead>
        <tbody>
          {renderRows(page)}
        </tbody>
      </table>

      <p className="p-title-info"><b>Perfil de Maturidade</b></p>
      <table className="w3-table-all" style={{ marginTop: 30 }}>
        <thead>
          <tr>
          <th className="table-cor-clara">Empresa</th>
            <th className="table-cor-escura">Sites Auditados</th>
            <th className="table-cor-clara">Validade</th>
            <th className="table-cor-escura">Órgão Certificador</th>
          </tr>
        </thead>
        <tbody>
          {renderRows(page2)}
        </tbody>
      </table>
      <div>
           <p className="p-title-info">Empresas em Certificação</p>
           <p className="p-text-info">{totalRows} empresas estão em fase de certificação do Selo de Ética e/ou Perfil de Maturidade.</p>
            </div>
    </div>
  );
}

export default EmpresaCertificado;